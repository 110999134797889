<template>
  <section class="section_ageLegal overlay">
    <div class="ageLegal-content">
      <div class="container">
        <img class="ageLegal-content-logo" src="/images/logo/1602_logo.png" alt="1602 icon" />
        <h1>Are you of legal drinking age?</h1>
        <h1>您已达到法定饮酒年龄吗?</h1>
        <button @click="legalAccept('true')">Yes</button>
        <button @click="legalAccept('false')">No</button>
      </div>
    </div>

    <div class="ageLegal-foot">
      <div class="container">
        <div class="ageLegal-foot-social">
          <a href="https://www.facebook.com/1602craftbeer/"><img src="/images/icon/facebook.png" alt="facebook"></a>
          <a href="https://www.instagram.com/1602craftbeer_/"><img src="/images/icon/instagram.png" alt="instagram"></a>
          <a href="https://www.youtube.com/@CraftBeer-db2sn"><img src="/images/icon/youtube.png" alt="youtube"></a>
        </div>
        <div class="ageLegal-foot-info">
          <p>By entering this website you agree you are a non-Muslim.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AgeLegalPage',
  data() {

  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.$store.state.legalAccept = localStorage.getItem('legalCondition');

  },
  methods: {
    legalAccept(value) {
      this.$store.state.legalAccept = value;
      localStorage.setItem('legalCondition', this.$store.state.legalAccept);
      this.$store.state.legalAccept = localStorage.getItem('legalCondition');
      var languageSelected = (this.$route.params.lang != 'en' && this.$route.params.lang != 'cn') ? 'en' : this.$route.params.lang;
      localStorage.setItem("language", languageSelected);
    },
  },
}
</script>

<style scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; /* Ensure the overlay is on top of other content */
    overflow-y: auto;
  }
</style>