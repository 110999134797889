import { createRouter, createWebHistory } from "vue-router";
import { i18n } from '../main'
import store from '@/store'

let routeExternalData = [];

const routes = [
  {
    path: '/',
    name: 'Home Page',
    component: () => import("../views/HomeView.vue"),
    meta: {
      requiresLegalAcceptance: true,
      title: "1602 Craft Beer - Home Page",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Home Page" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Home Page" },
        { itemprop: "name", content: "1602 Craft Beer - Home Page" },
        { property: "og:title", content: "1602 Craft Beer - Home Page" },
        { property: "og:description", content: "1602 Craft Beer - Home Page" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang',
      },
      {
        path: '/:lang/index.html',
      }
    ]
  },
  {
    path: '/en',
    redirect: '/',
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: '/',
  },
  {
    path: '/our_beer/lager',
    name: 'Our Beer - Lager',
    component: () => import('../views/OurBeers/LagerView.vue'),
    meta: {
      title: "1602 Craft Beer - Lager",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Lager" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Lager" },
        { itemprop: "name", content: "1602 Craft Beer - Lager" },
        { property: "og:title", content: "1602 Craft Beer - Lager" },
        { property: "og:description", content: "1602 Craft Beer - Lager" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/our_beer/lager',
      },
      {
        path: '/our_beer/lager/index.html',
      },
      {
        path: '/:lang/our_beer/lager/index.html',
      }
    ]
  },
  {
    path: '/our_beer/pale_ale',
    name: 'Our Beer - Pale Ale',
    component: () => import('../views/OurBeers/PaleAleView.vue'),
    meta: {
      title: "1602 Craft Beer - Pale Ale",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Pale Ale" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Pale Ale" },
        { itemprop: "name", content: "1602 Craft Beer - Pale Ale" },
        { property: "og:title", content: "1602 Craft Beer - Pale Ale" },
        { property: "og:description", content: "1602 Craft Beer - Pale Ale" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/our_beer/pale_ale',
      },
      {
        path: '/our_beer/pale_ale/index.html',
      },
      {
        path: '/:lang/our_beer/pale_ale/index.html',
      }
    ]
  },
  {
    path: '/our_beer/extra_dark',
    name: 'Our Beer - Extra Dark',
    component: () => import('../views/OurBeers/ExtraDarkView.vue'),
    meta: {
      title: "1602 Craft Beer - Extra Dark",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Extra Dark" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Extra Dark" },
        { itemprop: "name", content: "1602 Craft Beer - Extra Dark" },
        { property: "og:title", content: "1602 Craft Beer - Extra Dark" },
        { property: "og:description", content: "1602 Craft Beer - Extra Dark" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/our_beer/extra_dark',
      },
      {
        path: '/our_beer/extra_dark/index.html',
      },
      {
        path: '/:lang/our_beer/extra_dark/index.html',
      }
    ]
  },
  {
    path: '/our_beer/wheat_beer',
    name: 'Our Beer - Wheat Beer',
    component: () => import('../views/OurBeers/WheatBeerView.vue'),
    meta: {
      title: "1602 Craft Beer - Wheat Beer",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Wheat Beer" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Wheat Beer" },
        { itemprop: "name", content: "1602 Craft Beer - Wheat Beer" },
        { property: "og:title", content: "1602 Craft Beer - Wheat Beer" },
        { property: "og:description", content: "1602 Craft Beer - Wheat Beer" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/our_beer/wheat_beer',
      },
      {
        path: '/our_beer/wheat_beer/index.html',
      },
      {
        path: '/:lang/our_beer/wheat_beer/index.html',
      }
    ]
  },
  {
    path: '/our_story',
    name: 'Our Story',
    component: () => import('../views/OurStory.vue'),
    meta: {
      title: "1602 Craft Beer - Our Story",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Our Story" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Our Story" },
        { itemprop: "name", content: "1602 Craft Beer - Our Story" },
        { property: "og:title", content: "1602 Craft Beer - Our Story" },
        { property: "og:description", content: "1602 Craft Beer - Our Story" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/our_story',
      },
      {
        path: '/our_story/index.html',
      },
      {
        path: '/:lang/our_story/index.html',
      }
    ]
  },
  {
    path: '/event_services',
    name: 'Event Services',
    component: () => import('../views/EventServices.vue'),
    meta: {
      title: "1602 Craft Beer - Event Services",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Event Services" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Event Services" },
        { itemprop: "name", content: "1602 Craft Beer - Event Services" },
        { property: "og:title", content: "1602 Craft Beer - Event Services" },
        { property: "og:description", content: "1602 Craft Beer - Event Services" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/event_services',
      },
      {
        path: '/event_services/index.html',
      },
      {
        path: '/:lang/event_services/index.html',
      }
    ]
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue'),
    meta: {
      title: "1602 Craft Beer - FAQ",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - FAQ" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - FAQ" },
        { itemprop: "name", content: "1602 Craft Beer - FAQ" },
        { property: "og:title", content: "1602 Craft Beer - FAQ" },
        { property: "og:description", content: "1602 Craft Beer - FAQ" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/faq',
      },
      {
        path: '/faq/index.html',
      },
      {
        path: '/:lang/faq/index.html',
      }
    ]
  },
  {
    path: '/terms_conditions',
    name: 'TermsConditions',
    component: () => import('../views/TermsCondition.vue'),
    meta: {
      title: "1602 Craft Beer - Terms Conditions",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Terms Conditions" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Terms Conditions" },
        { itemprop: "name", content: "1602 Craft Beer - Terms Conditions" },
        { property: "og:title", content: "1602 Craft Beer - Terms Conditions" },
        { property: "og:description", content: "1602 Craft Beer - Terms Conditions" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/terms_conditions',
      },
      {
        path: '/terms_conditions/index.html',
      },
      {
        path: '/:lang/terms_conditions/index.html',
      }
    ]
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      title: "1602 Craft Beer - Privacy Policy",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Privacy Policy" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Privacy Policy" },
        { itemprop: "name", content: "1602 Craft Beer - Privacy Policy" },
        { property: "og:title", content: "1602 Craft Beer - Privacy Policy" },
        { property: "og:description", content: "1602 Craft Beer - Privacy Policy" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/privacy_policy',
      },
      {
        path: '/privacy_policy/index.html',
      },
      {
        path: '/:lang/privacy_policy/index.html',
      }
    ],
  },
  {
    path: '/refund_policy',
    name: 'RefundPolicy',
    component: () => import('../views/RefundPolicy.vue'),
    meta: {
      title: "1602 Craft Beer - Refund Policy",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Refund Policy" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Refund Policy" },
        { itemprop: "name", content: "1602 Craft Beer - Refund Policy" },
        { property: "og:title", content: "1602 Craft Beer - Refund Policy" },
        { property: "og:description", content: "1602 Craft Beer - Refund Policy" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/refund_policy',
      },
      {
        path: '/refund_policy/index.html',
      },
      {
        path: '/:lang/refund_policy/index.html',
      }
    ],
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import('../views/CareersPage.vue'),
    meta: {
      title: "1602 Craft Beer - Careers",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Careers" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Careers" },
        { itemprop: "name", content: "1602 Craft Beer - Careers" },
        { property: "og:title", content: "1602 Craft Beer - Careers" },
        { property: "og:description", content: "1602 Craft Beer - Careers" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/careers',
      },
      {
        path: '/careers/index.html',
      },
      {
        path: '/:lang/careers/index.html',
      }
    ]
  },
  {
    path: '/profile_dashboard',
    name: 'ProfileDashboard',
    component: () => import('../views/Profile/ProfileDashboard.vue'),
    meta: {
      title: "1602 Craft Beer - Profile Dashboard",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Profile Dashboard" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Profile Dashboard" },
        { itemprop: "name", content: "1602 Craft Beer - Profile Dashboard" },
        { property: "og:title", content: "1602 Craft Beer - Profile Dashboard" },
        { property: "og:description", content: "1602" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/profile_dashboard',
      },
      {
        path: '/profile_dashboard/index.html',
      },
      {
        path: '/:lang/profile_dashboard/index.html',
      }
    ]
  },
  {
    path: '/profile_order_history',
    name: 'ProfileOrderHistory',
    component: () => import('../views/Profile/ProfileOrderHistory.vue'),
    meta: {
      title: "1602 Craft Beer - Profile Order History",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Profile Order History" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Profile Order History" },
        { itemprop: "name", content: "1602 Craft Beer - Profile Order History" },
        { property: "og:title", content: "1602 Craft Beer - Profile Order History" },
        { property: "og:description", content: "1602 Craft Beer - Profile Order History" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/profile_order_history',
      },
      {
        path: '/profile_order_history/index.html',
      },
      {
        path: '/:lang/profile_order_history/index.html',
      }
    ]
  },
  {
    path: '/profile_address_book',
    name: 'ProfileAddressBook',
    component: () => import('../views/Profile/ProfileAddressBook.vue'),
    meta: {
      title: "1602 Craft Beer - Profile Address Book",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Profile Address Book" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Profile Address Book" },
        { itemprop: "name", content: "1602 Craft Beer - Profile Address Book" },
        { property: "og:title", content: "1602 Craft Beer - Profile Address Book" },
        { property: "og:description", content: "1602 Craft Beer - Profile Address Book" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/profile_address_book',
      },
      {
        path: '/profile_address_book/index.html',
      },
      {
        path: '/:lang/profile_address_book/index.html',
      }
    ]
  },
  {
    path: '/profile_coupons',
    name: 'ProfileCoupons',
    component: () => import('../views/Profile/ProfileVouchers.vue'),
    meta: {
      title: "1602 Craft Beer - Profile Coupons",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Profile Coupons" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Profile Coupons" },
        { itemprop: "name", content: "1602 Craft Beer - Profile Coupons" },
        { property: "og:title", content: "1602 Craft Beer - Profile Coupons" },
        { property: "og:description", content: "1602 Craft Beer - Profile Coupons" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/profile_coupons',
      },
      {
        path: '/profile_coupons/index.html',
      },
      {
        path: '/:lang/profile_coupons/index.html',
      }
    ]
  },
  {
    path: '/profile_account_details',
    name: 'ProfileAccountDetails',
    component: () => import('../views/Profile/ProfileAccountDetails.vue'),
    meta: {
      title: "1602 Craft Beer - Profile Account Details",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Profile Account Details" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Profile Account Details" },
        { itemprop: "name", content: "1602 Craft Beer - Profile Account Details" },
        { property: "og:title", content: "1602 Craft Beer - Profile Account Details" },
        { property: "og:description", content: "1602 Craft Beer - Profile Account Details" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/profile_account_details',
      },
      {
        path: '/profile_account_details/index.html',
      },
      {
        path: '/:lang/profile_account_details/index.html',
      }
    ]
  },
  {
    path: '/profile_point_history',
    name: 'ProfilePointHistory',
    component: () => import('../views/Profile/ProfilePointHistory.vue'),
    meta: {
      title: "1602 Craft Beer - Profile Point History",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Profile Point History" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Profile Point History" },
        { itemprop: "name", content: "1602 Craft Beer - Profile Point History" },
        { property: "og:title", content: "1602 Craft Beer - Profile Point History" },
        { property: "og:description", content: "1602 Craft Beer - Profile Point History" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/profile_point_history',
      },
      {
        path: '/profile_point_history/index.html',
      },
      {
        path: '/:lang/profile_point_history/index.html',
      }
    ]
  },
  {
    path: "/product/:slug",
    name: "Product",
    component: () => import("../views/ShopProduct/ShopProductInner.vue"),
    meta: {
      title: "1602 Craft Beer - Product",
      params: {
        slug: "",
      },
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Product" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Product" },
        { itemprop: "name", content: "1602 Craft Beer - Product" },
        { property: "og:title", content: "1602 Craft Beer - Product" },
        { property: "og:description", content: "1602 Craft Beer - Product" },
        { property: "og:url", content: "/" },
      ],
    },
    children: [
      {
        path: '/:lang/product/:slug',
      },
      {
        path: '/product/:slug' + '/index.html',
      },
      {
        path: '/:lang/product/:slug' + '/index.html',
      }
    ]
  },
  {
    path: '/shop',
    name: 'Product - Shop All',
    component: () => import('../views/ProductListing.vue'),
    meta: {
      title: "1602 Craft Beer - Shop All",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Shop All" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Shop All" },
        { itemprop: "name", content: "1602 Craft Beer - Shop All" },
        { property: "og:title", content: "1602 Craft Beer - Shop All" },
        { property: "og:description", content: "1602 Craft Beer - Shop All" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/shop',
      },
      {
        path: '/shop/index.html',
      },
      {
        path: '/:lang/shop/index.html',
      }
    ]
  },
  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: () => import('../views/Checkout/CheckoutMain.vue'),
    meta: {
      title: "1602 Craft Beer - Checkout",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Checkout" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602" },
        { itemprop: "name", content: "1602 Craft Beer - Checkout" },
        { property: "og:title", content: "1602 Craft Beer - Checkout" },
        { property: "og:description", content: "1602 Craft Beer - Checkout" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/checkout',
      },
      {
        path: '/checkout/index.html',
      },
      {
        path: '/:lang/checkout/index.html',
      }
    ]
  },
  {
    path: '/cart',
    name: 'CartPage',
    component: () => import('../views/CartPage/CartView.vue'),
    meta: {
      title: "1602 - Cart",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Cart" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Cart" },
        { itemprop: "name", content: "1602 Craft Beer - Cart" },
        { property: "og:title", content: "1602 Craft Beer - Cart" },
        { property: "og:description", content: "1602 Craft Beer - Cart" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/cart',
      },
      {
        path: '/cart/index.html',
      },
      {
        path: '/:lang/cart/index.html',
      }
    ]
  },
  {
    path: '/events',
    name: 'EventsPage',
    component: () => import('../views/EventsLanding/Events.vue'),
    meta: {
      title: "1602 Craft Beer - Events",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Events" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Events" },
        { itemprop: "name", content: "1602 Craft Beer - Events" },
        { property: "og:title", content: "1602 Craft Beer - Events" },
        { property: "og:description", content: "1602 Craft Beer - Events" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/events',
      },
      {
        path: '/events/index.html',
      },
      {
        path: '/:lang/events/index.html',
      }
    ]
  },
  {
    path: '/events_form',
    name: 'EventsForm',
    component: () => import('../views/EventsPage.vue'),
    meta: {
      title: "1602 Craft Beer - Events Form",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Events Form" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Events Form" },
        { itemprop: "name", content: "1602 Craft Beer - Events Form" },
        { property: "og:title", content: "1602 Craft Beer - Events Form" },
        { property: "og:description", content: "1602 Craft Beer - Events Form" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/events_form',
      },
      {
        path: '/events_form/index.html',
      },
      {
        path: '/:lang/events_form/index.html',
      }
    ]
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/Login/LoginView.vue'),
    meta: {
      title: "1602 Craft Beer - Login",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Login" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Login" },
        { itemprop: "name", content: "1602 Craft Beer - Login" },
        { property: "og:title", content: "1602 Craft Beer - Login" },
        { property: "og:description", content: "1602 Craft Beer - Login" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/login',
      },
      {
        path: '/login/index.html',
      },
      {
        path: '/:lang/login/index.html',
      }
    ]
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: () => import('../views/Register/RegisterView.vue'),
    meta: {
      title: "1602 Craft Beer - Register",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Register" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Register" },
        { itemprop: "name", content: "1602 Craft Beer - Register" },
        { property: "og:title", content: "1602 Craft Beer - Register" },
        { property: "og:description", content: "1602 Craft Beer - Register" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/register',
      },
      {
        path: '/register/index.html',
      },
      {
        path: '/:lang/register/index.html',
      }
    ]
  },
  {
    path: '/shipping_info',
    name: 'ShippingInfo',
    component: () => import('../views/ShipInfo/ShipInfoView.vue'),
    meta: {
      title: "1602 Craft Beer - Shipping Information",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Shipping Information" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Shipping Information" },
        { itemprop: "name", content: "1602 Craft Beer - Shipping Information" },
        { property: "og:title", content: "1602 Craft Beer - Shipping Information" },
        { property: "og:description", content: "1602 Craft Beer - Shipping Information" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/shipping_info',
      },
      {
        path: '/shipping_info/index.html',
      },
      {
        path: '/:lang/shipping_info/index.html',
      }
    ]
  },
  {
    path: '/feedback',
    name: 'FeedbackForm',
    component: () => import('../views/FeedbackPage.vue'),
    meta: {
      title: "1602 Craft Beer - Feedback Form",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Feedback Form" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Feedback Form" },
        { itemprop: "name", content: "1602 Craft Beer - Feedback Form" },
        { property: "og:title", content: "1602 Craft Beer - Feedback Form" },
        { property: "og:description", content: "1602 Craft Beer - Feedback Form" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/feedback',
      },
      {
        path: '/feedback/index.html',
      },
      {
        path: '/:lang/feedback/index.html',
      }
    ]
  },
  {
    path: '/store_locator',
    name: 'StoreLocator',
    component: () => import('../views/StoreLocator.vue'),
    meta: {
      title: "1602 Craft Beer - Store Locator",
      metaTags: [
        { name: "description", content: "1602 Craft Beer - Store Locator" },
        { itemprop: "url", content: "/" },
        { name: "twitter:url", content: "/" },
        { name: "twitter:description", content: "1602 Craft Beer - Store Locator" },
        { itemprop: "name", content: "1602 Craft Beer - Store Locator" },
        { property: "og:title", content: "1602 Craft Beer - Store Locator" },
        { property: "og:description", content: "1602 Craft Beer - Store Locator" },
        { property: "og:url", content: "/" },

      ],
    },
    children: [
      {
        path: '/:lang/store_locator',
      },
      {
        path: '/store_locator/index.html',
      },
      {
        path: '/:lang/store_locator/index.html',
      }
    ]
  },
]

let routeFinalData = routes.concat(routeExternalData);

const router = createRouter({
  history: createWebHistory(
    process.env.BASE_URL),
  routes: routeFinalData,
})


// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  i18n.locale = localStorage.getItem('language') || 'en';

  const newLocale = to.params.lang !== 'cn' ? 'en' : 'cn';
  localStorage.language = newLocale;
  store.state.multiLanguage = localStorage.language == 'en' ? '/' : `/${localStorage.language}/`;
  i18n.global.locale = newLocale;

  if (newLocale === 'cn') {
    document.documentElement.style.setProperty('--current-font-family', 'var(--font-family-chinese)');
  } else {
    document.documentElement.style.setProperty('--current-font-family', 'var(--font-family-secondary)');
  }

  if (localStorage.access_token) {
    store.state.isLoggedIn = true;
  }
  
  next();
});

export default router;
