import globals from "@/config/globals.js";
import { getGuestID, postAPI } from "./general";
import { useToast } from 'vue-toast-notification';
import store from '../store/index.js';
import { i18n } from '../main'

const loginAPI = async (username, password, mergeCart = false) => {
    var data = {
        username: username,
        password: password,
    };
    const $toast = useToast();
    if (mergeCart) {
        data['merge_cart'] = '1';
        data['guest_id'] = getGuestID();
    }
    var response = await postAPI('/login', data);
    if (response.code == 'success') {
        store.state.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        store.state.access_token = response.data.token;
        globals.user = response.data;
    } else {
        var errorMessage = i18n.global.t('login_failed');
        // if (response.message) {
        //     errorMessage.message;
        // }
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const loginOtpAPI = async (username, type) => {
    var data = {
        target: username,
        type: type,
    };
    const $toast = useToast();
    var response = await postAPI('/login/phone', data);
    if (response.code == 'success') {
        $toast.open({
            message: i18n.global.t('login.enter_otp_info'),
            type: 'success',
            position: 'bottom'
        });
    } else {
        $toast.open({
            message: response.message,
            type: 'error',
            position: 'bottom'
        });
    }

    return response;
}

const verifyLoginOtpAPI = async (username, type, verification_code, mergeCart = false) => {
    var data = {
        target: username,
        type: type,
        code: verification_code,
    };
    const $toast = useToast();
    if (mergeCart) {
        data['merge_cart'] = '1';
        data['guest_id'] = getGuestID();
    }
    var response = await postAPI('/login/phone/verify', data);
    if (response.code == 'success') {
        store.state.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        store.state.access_token = response.data.token;
        globals.user = response.data;
        store.state.user = response.data;

        $toast.open({
            message: i18n.global.t('logged_in'),
            type: 'success',
            position: 'bottom'
        });
    } else {
        $toast.open({
            message: response.message,
            type: 'error',
            position: 'bottom'
        });
    }

    return response;
}

const facebookAPI = async (access_token, user_id, mergeCart = false, cartLogin = false, trackHash) => {
    var data = {
        access_token: access_token,
        user_id: user_id,
    };

    const $toast = useToast();
    if (mergeCart) {
        data['merge_cart'] = '1';
        data['guest_id'] = getGuestID();
    }

    var response = await postAPI('/login/checkFacebook', data);
    if (response.code == 'success') {
        store.state.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        store.state.access_token = response.data.token;
        globals.user = response.data;
        if (cartLogin) {
            window.location = '/checkout/cart';
        } else if (trackHash == 'order_shipped' || trackHash == 'order_failed') {
            window.location = '/account/orders_tracking#' + trackHash;
        } else {
            window.location = '/';
        }
    } else {
        var errorMessage = i18n.global.t('login_failed');
        // if (response.message) {
        //     errorMessage.message;
        // }
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const googleAPI = async (code, mergeCart = false) => {
    var data = {
        code: code,
    };
    if (mergeCart) {
        data['merge_cart'] = '1';
        data['guest_id'] = getGuestID();
    }
    const $toast = useToast();
    var response = await postAPI('/login/checkGoogle', data);
    if (response.code == 'success') {
        store.state.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        store.state.access_token = response.data.token;
        store.state.tempRegisterUserData = response.data;
        globals.user = response.data;
    } else {
        var errorMessage = i18n.global.t('login_failed');
        // if (response.message) {
        //     errorMessage.message;
        // }
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const registerAPI = async (firstname, email, phone, dob) => {

    var data = {
        firstname: firstname,
        email: email,
        phone: phone,
        dob: dob,
        registration_type: 'phone'
    };

    // if (mergeCart) {
    //     data['merge_cart'] = '1';
    //     data['guest_id'] = getGuestID();
    // }

    const $toast = useToast();
    var response = await postAPI('/register', data);
    if (response.code == 'success') {
        store.state.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        store.state.access_token = response.data.token;
        globals.user = response.data;
    } else {
        var errorMessage = i18n.global.t('register.registration_failed');

        if (response.code == 'email_exist') {
            response.message = i18n.global.t('register.email_exist');

        } else if (response.code == 'invalid_email_input') {
            response.message = i18n.global.t('register.invalid_email');

        } else if (response.code == 'invalid_phone') {
            response.message = i18n.global.t('register.invalid_phone');

        } else if (response.code == 'invalid_password') {
            response.message = i18n.global.t('register.invalid_password');

        } else if (response.code == 'invalid_firstname_input') {
            response.message = i18n.global.t('register.invalid_firstname');

        } else if (response.code == 'invalid_lastname_input') {
            response.message = i18n.global.t('register.invalid_lastname');

        } else if (response.code == 'invalid_gender_input') {
            response.message = i18n.global.t('register.invalid_gender');

        } else if (response.code == 'invalid_dob_input') {
            response.message = i18n.global.t('register.invalid_dob');

        } else if (response.code == 'phone_exist') {
            response.message = i18n.global.t('register.phone_exist');

        }

        if (response.message) {
            errorMessage = response.message;
        }
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const logoutAPI = async () => {
    await postAPI('/logout');
    const $toast = useToast();
    store.state.isLoggedIn = false;
    localStorage.access_token = '';
    store.state.access_token = null;
    globals.user = null;
    $toast.open({
        message: i18n.global.t('logged_out'),
        type: 'success',
        position: 'bottom'
    });
}

const verifyLoginAPI = async () => {
    return await postAPI('/verify', {}, true);
}

const forgotPasswordAPI = async (target, type) => {
    var data = {
        target: target,
        type: type,
    };

    const $toast = useToast();
    var response = await postAPI('/login/forgotPassword', data);
    if (response.code == 'success') {
        if (type == 'email') {
            $toast.open({
                message: 'New password has been sent to your email.',
                type: 'success',
                position: 'bottom'
            });
        } else {
            $toast.open({
                message: 'New password has been sent to your phone.',
                type: 'success',
                position: 'bottom'
            });
        }
    } else {
        var errorMessage = response.message;
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const verifyaccount = async (target, type, code) => {
    var data = {
        target: target,
        type: type,
        code: code,
    };

    const $toast = useToast();
    var response = await postAPI('/accountverification', data);
    if (response.code == 'success') {
        if (type == 'email') {
            $toast.open({
                message: i18n.global.t('register.email_verified'),
                type: 'success',
                position: 'bottom'
            });
        } else {
            $toast.open({
                message: i18n.global.t('register.phone_verified'),
                type: 'success',
                position: 'bottom'
            });
        }
    } else {
        if (type == 'email') {
            $toast.open({
                message: i18n.global.t('register.email_verify_failed'),
                type: 'error',
                position: 'bottom'
            });
        } else {
            $toast.open({
                message: i18n.global.t('register.phone_verify_failed'),
                type: 'error',
                position: 'bottom'
            });
        }
    }
    return response;
}

export {
    loginAPI,
    registerAPI,
    forgotPasswordAPI,
    verifyLoginAPI,
    logoutAPI,
    verifyaccount,
    googleAPI,
    facebookAPI,
    loginOtpAPI,
    verifyLoginOtpAPI
};